import React from 'react';
import * as styles from '../Form.module.scss';

export default function EmailField() {
  const checkDataPattern = {
    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    // value: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
    message: `Please provide your email`,
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailError = document.querySelector(`#emailError`);
    if (emailError) {
      if (e.target.value === '') {
        emailError.textContent = `E-mail is a required field`;
      } else if (!checkDataPattern.value.test(e.target.value)) {
        emailError.textContent = `Not a valid email.`;
      } else {
        emailError.textContent = ``;
      }
    }
  };

  return <input
    onBlur = {handleChange}
    type="email"
    placeholder='E-mail'
    autoComplete="email"
    aria-describedby={`email-error`}
    required
    className={styles.input}
  />;
}
