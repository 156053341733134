import React from 'react';
import { isValidPhoneNumber } from 'libphonenumber-js';
import * as styles from '../Form.module.scss';

export default function PhoneField() {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const phoneError = document.querySelector(`#phoneError`);
    if (phoneError) {
      if (e.target.value === '') {
        phoneError.textContent = `Phone number is a required field`;
      } else if (!isValidPhoneNumber(e.target.value, 'US')) {
        phoneError.textContent = `Not a valid phone number.`;
      } else {
        phoneError.textContent = ``;
      }
    }
  };

  return <input
    onBlur = {handleChange}
    type="email"
    className={styles.input}
    maxLength={16}
    placeholder='Phone Number'
    autoComplete="tel"
    aria-label="Phone Number"
    aria-describedby={`phone-error`}
  />;
}
