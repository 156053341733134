import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Section from '../../layout/Section';
import heroBg from '../../../images/contact/bg_banner.jpg';
import { colors } from '../../../includes/style';
import Container from '../../layout/Container';

const StyledSection = styled(Section)`
  background: ${colors.blue} url(${heroBg});
  background-repeat: no-repeat;
  background-position: center bottom;
  /* background-attachment: scroll; */
  background-size: cover;
  color: #fff;

  ${Container}{
    display: flex;
    flex-direction: column;
    min-height: 300px;
    font-family: "Lato", Helvetica, Arial, sans-serif;
    text-align: left;
    max-width: 960px;
    margin: auto;

    h1{
        font-weight: bold;
        font-size: 24px;
        text-align: left;
        font-family: "Lato", Helvetica, Arial, sans-serif;
        font-weight: 500;
      }
    
      p {
        font-size: 24px;
      }
  }
`;

type ContactHeroProps = {
  headline?: ReactNode,
  subheadline?: ReactNode,
};
// Regarding H1 tags and SEO https://moz.com/blog/h1-seo-experiment
export default function ContactHero({
  headline = <>Contact</>,
  subheadline = <>Please fill out the form below to get in touch with our sales team.</>,
}: ContactHeroProps) {
  return <StyledSection>
    <Container>
      <h1 className="hero-headline">{headline}</h1>
      <p className="hero-subheadline">{subheadline}</p>
    </Container>
  </StyledSection>;
}
