import React, { useState } from 'react';
import Container from '../layout/Container';
import Button from '../utils/Button';
import EmailField from './fields/EmailField';
import PhoneField from './fields/PhoneField';
import * as styles from './Form.module.scss';

export default function ContactUsForm() {
  const [formData, setFormData] = useState({ email: '', password: '' });

  const onSubmit = async (event: React.FormEvent) => {
    console.log(event);
    console.log(formData);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    const errorField = e.target.name === 'firstName' ? document.querySelector(`#firstError`) : document.querySelector(`#lastError`);
    if (errorField) {
      if (e.target.value === '') {
        errorField.textContent = e.target.name === 'firstName' ? 'First Name is a required field.' : 'Last Name is a required field.';
      }
    }
  };

  const handleMessageChange = () => {
    console.log('message changed');
  };

  return <section data-testid="FormSection" className={styles.FormSection}>
    <Container>
      <h2>Get in Touch</h2>
      <div className={styles.formContainer} >
        <form id="contactUsForm" onSubmit={onSubmit}>
          <div className={styles.row}>
            <div className={styles.smallCol}>
              <div className={styles.formWrap}>
                <span className={styles.errorWrap} id="firstError"></span>
                <input type="text"  onBlur={handleChange} name="firstName" placeholder="First Name" />
              </div>
            </div>
            <div className={styles.smallCol}>
              <div className={styles.formWrap}>
                <span className={styles.errorWrap} id="lastError"></span>
                <input type="text" className={styles.input} onBlur={handleChange} name="lastName" placeholder="Last Name" />
              </div>
            </div>
            <div className={styles.smallCol}>
              <div className={styles.formWrap}>
                <span className={styles.errorWrap} id="emailError"></span>
                <EmailField />
              </div>
            </div>
            <div className={styles.smallCol}>
              <div className={styles.formWrap}>
                <span className={styles.errorWrap} id="phoneError"></span>
                <PhoneField />
              </div>
            </div>
            <div className={styles.wideCol}>
              <div className={styles.formWrap}>
                <span className={styles.errorWrap} id="messageError"></span>
                <textarea id="message" name="message" onBlur={handleMessageChange} placeholder="Message" />
              </div>
            </div>
            <div className={styles.formButton}>
              <Button variant="blue" type="submit" className={styles.formButton}>Send Message</Button>
            </div>
          </div>
        </form>
      </div>
    </Container>
  </section>;
}
