import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, PageProps } from 'gatsby';
import ContactHero from '../components/sections/contact/ContactHero';
import ContactUsForm from '../components/forms/ContactUsForm';

export default function Contact({ data }: PageProps<ContactQueryData>) {
  return <>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <meta name="description" content={data.site.siteMetadata.description} />
      <title>{data.site.siteMetadata.name}</title>
    </Helmet>
    <ContactHero />
    <ContactUsForm />
  </>;
}

type ContactQueryData = {
  site: {
    siteMetadata: {
      name: string,
      description: string,
    }
  }
};
export const query = graphql`
  query {
    site {
      siteMetadata {
        name
        description
      }
    }
  }
`;